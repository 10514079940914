import axios from 'axios';
import config from '../config'
import { getParams } from '../utils'
import { signInWithSpotify } from './index'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const axiosInstance = axios.create({
    baseURL: "https://api.spotify.com/v1"
});

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.common['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

const refreshAuthLogic = failedRequest => axios.get(`${config.FIREBASE_BASE_URL}/getFreshToken`, {
    params: {
        payload: getParams()
    }
}).then(tokenRefreshResponse => {
    localStorage.setItem('token', tokenRefreshResponse.data.access_token);
    if (tokenRefreshResponse.data?.refresh_token) {
        localStorage.setItem('refresh_token', tokenRefreshResponse.data.refresh_token);
    }
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.access_token;
    return Promise.resolve();
}).catch(e => {
    let err = { ...e }
    if (err.response.data.error === 'invalid_grant') signInWithSpotify()
})


// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export default axiosInstance
