import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="43"
            fill="none"
            viewBox="0 0 46 43"
        >
            <path
                stroke="#27C195"
                strokeWidth="1.892"
                d="M44.552 21.5c0 11.283-9.563 20.5-21.445 20.5C11.224 42 1.66 32.782 1.66 21.5 1.661 10.216 11.224 1 23.106 1c11.883 0 21.446 9.216 21.446 20.5z"
            ></path>
            <path
                fill="#27C195"
                d="M34.106 22.055c0-6.076-4.923-11-10.999-11-6.075 0-10.998 4.924-10.998 11 0 5.499 3.991 10.067 9.269 10.865v-7.672h-2.794v-3.193h2.794V19.66c0-2.75 1.64-4.302 4.124-4.302a16.2 16.2 0 012.484.221v2.706H26.61c-1.375 0-1.818.843-1.818 1.73v2.04h3.06l-.488 3.193h-2.572v7.672c5.277-.798 9.313-5.366 9.313-10.865z"
            ></path>
        </svg>
    );
}

export default Icon;
