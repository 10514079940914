import axios from './axios'

const saveTrack = async (trackId) => {
    try {
        return await axios.put('/me/tracks', {
            ids: [trackId]
        })

    } catch (e) {
            let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default saveTrack