import React from "react";
import PrivateRoute from '../PrivateRoute'
import ScrollToTop from '../utils/ScrollToTop'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Login from "../pages/Login";
import Privacy from "../pages/Privacy";
import Dashboard from "../pages/dashboard";
import Artists from "../pages/Artists";
import Tracks from "../pages/Tracks";
import Recent from "../pages/Recent/";
import Discover from "../pages/Discover";
import Playlist from "../pages/Playlist";

const Main = () => {

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/login" component={Login}></Route>
        <Route path="/playlist" component={Playlist}></Route>
        <Route>
          <Switch>
            <PrivateRoute exact path="/">
              <Redirect to="/dashboard" component={Dashboard} />
            </PrivateRoute>
            <PrivateRoute exact path="/dashboard" component={Dashboard}></PrivateRoute>
            <PrivateRoute exact path="/artists" component={Artists}></PrivateRoute>
            <PrivateRoute exact path="/tracks" component={Tracks}></PrivateRoute>
            <PrivateRoute exact path="/recent" component={Recent}></PrivateRoute>
            <PrivateRoute exact path="/discover" component={Discover}></PrivateRoute>
            <PrivateRoute exact path="/privacy-policy" component={Privacy}></PrivateRoute>
          </Switch>
        </Route>

      </Switch>


    </Router>
  );
};

export default Main;
