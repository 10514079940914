import { shuffle } from '../utils'
import axios from './axios'
import { isSavedTracks } from './index'

let items = [];

const getRecommendations = async (limit = 50, cache = true) => {
    try {
        if (items.length === 0 || !cache) {
            let { data: { items: artists } } = await axios.get('/me/top/artists', {
                params: {
                    limit: "5",
                    time_range: 'long_term',
                    offset: "0"
                }
            })
            let { data: { genres } } = await axios.get('/recommendations/available-genre-seeds');
            let { data: { tracks } } = await axios.get('/recommendations', {
                params: {
                    limit,
                    seed_artists: shuffle(artists.map(i => i.id)).slice(1, 2).join``,
                    seed_genres: shuffle(genres).slice(1, 3).join``
                }
            })
            items = tracks;
        }
        let savedTracks = await isSavedTracks(items.map(i => i.id))
        items = items.map((i, k) => {
            return {
                ...i,
                isSaved: savedTracks[k]
            }
        })
        return {
            data: items,
            error: false,
            loading: false
        }

    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default getRecommendations