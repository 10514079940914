import { shuffle } from '../utils'
import { isSavedTracks } from './index'
import axios from './axios'

let cached = {}

const getTopList = async (timeRange = "long_term", disableCache = false) => {
    try {
        if (!cached.hasOwnProperty('tracks') || disableCache) {
            let { data: { items: tracks } } = await axios.get('/me/top/tracks', {
                params: {
                    limit: "5",
                    time_range: timeRange,
                    offset: "0"
                }
            })
            let { data: { items: artists } } = await axios.get('/me/top/artists', {
                params: {
                    limit: "5",
                    time_range: timeRange,
                    offset: "0"
                }
            })

            let { data: { genres } } = await axios.get('/recommendations/available-genre-seeds');
            let { data: { tracks: recommendations } } = await axios.get('/recommendations', {
                params: {
                    limit: "5",
                    seed_artists: shuffle(artists.map(i => i.id)).slice(1, 2).join``,
                    seed_genres: shuffle(genres).slice(1, 3).join``
                }
            })

            let { data: { items: recently } } = await axios.get('/me/player/recently-played', {
                params: {
                    limit: "5"
                }
            })

            artists = await Promise.all(artists.map(async i => {
                let { data: { tracks } } = await axios.get(`/artists/${i.id}/top-tracks`, {
                    params: {
                        country: "US"
                    }
                })
                return {
                    ...i,
                    tracks
                }
            }));
            cached = {
                artists,
                tracks,
                recommendations,
                recently
            }

        }
        let savedTracks = await isSavedTracks(cached.recently.map(i => i.track.id))
        cached.recently = cached.recently.map((i, k) => {
            return {
                ...i,
                track: {
                    ...i.track,
                    isSaved: savedTracks[k]
                }
            }
        })
        return {
            data: cached,
            error: false,
            loading: false
        }
    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default getTopList