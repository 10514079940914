import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.167 1.708v9.125h9.125a8.334 8.334 0 11-9.125-9.125zm1.666 0a8.336 8.336 0 017.459 7.459h-7.459V1.708z"
        fill="#20C933"
        opacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.892 4.108a8.336 8.336 0 00-5.059-2.4v7.459h7.459a8.335 8.335 0 00-2.4-5.06z"
        fill="#20C933"
      />
    </svg>
  )
}

export default SvgComponent
