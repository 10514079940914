import axios from './axios'

export const play = async (spotify_uris, player_id) => {
    try {
        await axios.put(`/me/player/play?device_id=${player_id}`, {
            uris: spotify_uris
        })
        return true
    } catch (e) {
        let { response: { data: { message } } } = { ...e }
        return false
    }
}

export default play