import React from "react";

function Icon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="16"
            fill="none"
            viewBox="0 0 18 16"
        >
            <path
                fill="#000"
                d="M15.891 6.257l-2.587-1.481-1.48 2.587a.592.592 0 00.22.817.562.562 0 00.8-.221l.511-.902a4.811 4.811 0 01-1.634 4.646c-1.65 1.379-4.05 1.515-5.82.29a.6.6 0 00-.681.986 5.968 5.968 0 003.387 1.039 6.025 6.025 0 003.88-1.413 6.049 6.049 0 002.043-5.77l.748.426c.29.17.647.068.817-.222.17-.255.085-.612-.204-.782zM5.067 7.822a.617.617 0 00-.8.273l-.392.766a4.848 4.848 0 011.651-4.545c1.651-1.378 4.05-1.514 5.82-.289a.6.6 0 00.682-.987C9.815 1.525 6.82 1.678 4.76 3.414 3.024 4.861 2.275 7.176 2.752 9.337l-.885-.442a.594.594 0 00-.545 1.055l2.655 1.344L5.322 8.64a.61.61 0 00-.255-.817z"
            ></path>
        </svg>
    );
}

export default Icon;
