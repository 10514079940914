import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            opacity="0.7"
            strokeWidth="1"
            viewBox="0 0 24 24"
        >
            <path d="M11 5L6 9 2 9 2 15 6 15 11 19 11 5z"></path>
            <path d="M23 9L17 15"></path>
            <path d="M17 9L23 15"></path>
        </svg>
    );
}

export default Icon;
