const reducers = {
    GET_USER: "getCurrentUser",
    GET_TOPLIST: "getTopList",
    GET_ARTISTS: "getArtists",
    GET_TRACKS: "getTracks",
    GET_RECENTLY: "getRecently",
    SET_PLAYER: 'setPlayer',
    GET_RECOMMENDATIONS: "getRecommendations",
    SET_PLAYINGTRACKS: "setPlayingTracks",
    SET_SAVEDTRACKS: "setSavedTracks",
    SET_PLAYLIST: "setPlaylist",
    SET_CURRENTLYPLAYING: "setCurrentlyPlaying",
    SET_ISSAVED: "setIsSaved",
    SET_DEVICE: "setDeviceId",
    SET_COLLAPSE: "setCollapse",
    SET_TIMERANGE: "setTimeRange",
}

export default reducers