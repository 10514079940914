import config from '../config'
import axios from 'axios'

const getToken = async (code) => {
    try {
        let { data } = await axios.get(`${config.FIREBASE_BASE_URL}/getRefreshToken`, {
            params: {
                code,
                redirect_uri: config.SPOTIFY_CALLBACK_URL
            }
        })
        return data
    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default getToken