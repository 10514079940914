import React, { useEffect } from 'react'
import Portal from './portal'

function Modal({ setShowModal, children }) {

    const handleKeyDown = ({ key }) => {
        if (key === 'Escape') setShowModal(false)
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return function cleanup() {
            document.body.style.overflow = 'auto'
        }
    }, [])

    return (
        <Portal onKeyDown={handleKeyDown}>
            <div onKeyDown={handleKeyDown} tabIndex={-1} role="dialog" className="modal" onClick={() => setShowModal(false)}></div>
            {children}
        </ Portal>
    )
}

export default Modal
