import axios from './axios'

const getCurrentUser = async () => {
    try {
        let { data } = await axios.get('/me')
        return {
            data: data,
            loading: false,
            error: false
        }
    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default getCurrentUser