import React, { useEffect, useState, useContext, useRef, } from 'react'
import { play, isSavedTracks, removeSavedTrack, saveTrack } from '../../services/'
import { timeConvert, getParams } from '../../utils'
import loadSpotifyPlayer from '../../utils/LoadScript'
import { Context } from '../../context'
import Volume from './volume'
import axios from 'axios'
import config from '../../config'
import HeartIcon from '../../icons/heart'
import PlayIcon from '../../icons/play'
import NextIcon from '../../icons/next'
import PrevIcon from '../../icons/prev'
import _ from 'lodash'

export default function PlayerComponent() {

    let player

    const intervalCurrentState = useRef(null);

    const { dispatch, state } = useContext(Context);

    const [onSlide, setOnslide] = useState(false)

    const [playerVolume, setPlayerVolume] = useState(1)

    window.onSpotifyWebPlaybackSDKReady = async function () {
        player = new window.Spotify.Player({
            name: 'Echoes Player',
            getOAuthToken: async (cb) => {
                let access_token = localStorage.getItem('token')
                if (access_token) {
                    cb(access_token)
                } else {
                    let { data: { access_token } } = await axios.get(`${config.FIREBASE_BASE_URL}/getFreshToken`, {
                        params: {
                            payload: getParams()
                        }
                    })
                    cb(access_token);
                }
            }
        });
        // Error handling
        player.on('initialization_error', ({ message }) => { console.error(message, 'initialization_error'); });
        player.on('account_error', ({ message }) => { console.error(message, 'account_error'); });
        player.on('playback_error', ({ message }) => { console.error(message, 'playback_error'); });

        // Ready
        player.addListener('ready', ({ device_id }) => {
            dispatch({ type: "setPlayer", payload: player })
            dispatch({ type: "setDeviceId", payload: device_id })
        });

        // Not Ready
        player.addListener('not_ready', ({ device_id }) => {
            console.log('Device ID has gone offline', device_id);
        });

        player.addListener('player_state_changed', (data) => {
            if (!data) return false
            stopInterval()
            dispatch({ type: "setCurrentlyPlaying", payload: { data, loading: false } })
            if (data.paused) {
                stopInterval()
            } else {
                startInterval()
            }
        });
        await player.connect()
        return 'OK'
    }

    const handleChange = ({ currentTarget: { value, max, min } }) => {
        setOnslide(true)
        setDebounced.current(value, state.player);
    };

    const setServicesValue = (e, player) => {
        player.seek(e).then(() => {
            setTimeout(() => {
                setOnslide(false)
            }, 500);
        });
    };

    const handleSaveClick = async () => {
        try {
            let { isSaved, id } = state.playingTracks.isSaved.find(i => i.id === state.currentlyPlaying.data.track_window.current_track.id) || {};
            if (isSaved) {
                await removeSavedTrack(id);
                let savedList = await isSavedTracks(state.playingTracks.data.map(i => i.id))
                dispatch({
                    type: "setSavedTracks", payload:
                        state.playingTracks.data.map((i, k) => {
                            return {
                                id: i.id,
                                uri: i.uri,
                                isSaved: savedList[k]
                            }
                        })
                })

            } else {
                await saveTrack(state.currentlyPlaying.data.track_window.current_track.id)
                let savedList = await isSavedTracks(state.playingTracks.data.map(i => i.id))
                dispatch({
                    type: "setSavedTracks", payload:
                        state.playingTracks.data.map((i, k) => {
                            return {
                                id: i.id,
                                uri: i.uri,
                                isSaved: savedList[k]
                            }
                        })
                })
            }
        } catch (e) {
            throw new Error(e)
        }
    }


    function handleNextTrack() {
        state.player.nextTrack().then(() => {
            console.log('Skipped to next track!');
        });
    }

    function handlePreviousTrack() {
        state.player.previousTrack().then(() => {
            console.log('Set to previous track!');
        });
    }

    const setDebounced = useRef(_.debounce(setServicesValue, 500));

    function handleTogglePlay() {
        state.player.togglePlay()
    }

    const handleVolumeChange = (e) => {
        setPlayerVolume(e.target.value)
        state.player.setVolume(e.target.value)
    }

    const load = async () => {
        try {
            await loadSpotifyPlayer();
        } catch (e) {
            console.log(e)
        }
    }

    const startInterval = () => intervalCurrentState.current = setInterval(async () => {
        try {
            let data = await player.getCurrentState();
            if (data) {
                dispatch({ type: "setCurrentlyPlaying", payload: { data, loading: false } })
            }
        }
        catch (e) {
            console.log(e)
        }
    }, 1000)

    const stopInterval = () => clearInterval(intervalCurrentState.current)

    useEffect(() => {
        load();
    }, [])

    useEffect(async () => {
        if (state.playingTracks.data.length > 0) {
            let savedList = await isSavedTracks(state.playingTracks.data.map(i => i.id))
            dispatch({
                type: "setSavedTracks", payload:
                    state.playingTracks.data.map((i, k) => {
                        return {
                            id: i.id,
                            uri: i.uri,
                            isSaved: savedList[k]
                        }
                    })
            })
            let success = await play(state.playingTracks.data.map(i => i.uri), state.deviceId);

            if (!success) {
                await window.onSpotifyWebPlaybackSDKReady()
                play(state.playingTracks.data.map(i => i.uri), state.deviceId);
            }
        }
    }, [state.playingTracks.data])// eslint-disable-line react-hooks/exhaustive-deps

    if (state.currentlyPlaying.loading) return (
        <div className="player-container">
            <div className="echoes-player">
                <div className="album-info d-flex justify-content-between">
                    <figure className="figure ml-0">
                        <figcaption>

                        </figcaption>
                    </figure>
                    <HeartIcon fill="#b2b2b2" strokecolor="#b2b2b2" />
                </div>
                <div className="player">
                    <div className="player-buttons">
                        <PrevIcon />
                        <PlayIcon playing="false" />
                        <NextIcon />
                    </div>
                    <div className="progress-wrapper">
                        <span className="time-rest">00:00</span>
                        <input onChange={() => false} type="range" value={0} max={100} />
                        <span className="time-past">00:00</span>
                    </div>
                </div>
                <div className="volume-wrapper">
                    <Volume volume={playerVolume} />
                    <input onChange={() => false} className="ml-3" step={0.1} type="range" max={1} />
                </div>
            </div>
        </div>
    )


    return (
        <div className="player-container">
            <div className="echoes-player">
                <div className="album-info sm-hide d-flex justify-content-between">
                    <figure className="figure ml-0">
                        <img alt={state.currentlyPlaying.data.track_window.current_track.album.name} width="50" height="50" src={state.currentlyPlaying.data.track_window.current_track.album.images[0].url}></img>
                        <figcaption>
                            <p title={state.currentlyPlaying.data.track_window.current_track.name} className="figure-track-name">{state.currentlyPlaying.data.track_window.current_track.name}</p>
                            <p className="figure-artist-name">{state.currentlyPlaying.data.track_window.current_track.artists[0].name}</p>
                        </figcaption>
                    </figure>
                    <HeartIcon onClick={handleSaveClick} strokecolor={state.playingTracks.isSaved.find(i => i.id === state.currentlyPlaying.data.track_window.current_track.id)?.isSaved ? '#b2b2b2' : 'white'} fill={state.playingTracks.isSaved.find(i => i.id === state.currentlyPlaying.data.track_window.current_track.id)?.isSaved ? '#b2b2b2' : 'none'} />
                </div>
                <div className="player">
                    <div className="player-buttons">
                        {state.currentlyPlaying.data.track_window.previous_tracks.length > 0 ?
                            <PrevIcon onClick={handlePreviousTrack} /> :
                            <PrevIcon />}
                        <PlayIcon name={state.currentlyPlaying.data.track_window.current_track.name} playing={state.currentlyPlaying.paused} onClick={handleTogglePlay} />
                        {state.currentlyPlaying.data.track_window.next_tracks.length > 0 ?
                            <NextIcon onClick={handleNextTrack} /> :
                            <NextIcon />}
                    </div>
                    <div className="progress-wrapper">
                        <span className="time-rest">{timeConvert(state.currentlyPlaying.data.position)}</span>
                        <input onChange={handleChange} type="range" defaultValue={state.currentlyPlaying.data.position} value={onSlide ? null : state.currentlyPlaying.data.position} max={state.currentlyPlaying.data.duration} />
                        <span className="time-past">{timeConvert(state.currentlyPlaying.data.duration)}</span>
                    </div>
                </div>
                <div className="volume-wrapper">
                    <Volume volume={playerVolume} />
                    <input className="ml-3" step={0.1} onChange={handleVolumeChange} type="range" defaultValue={0.5} value={playerVolume} max={1} />
                </div>
            </div>
        </div>
    )
}
