import axios from './axios';

const isSavedTracks = async (trackIds) => {
    try {
        let { data } = await axios.get('/me/tracks/contains', {
            params: {
                ids: trackIds.join(',')
            }
        })
        return data
    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default isSavedTracks