import config from '../config'
import { queryBuilder } from '../utils'

const signInWithSpotify = () => {
    const loginUrl = queryBuilder(config.SPOTIFY_AUTHORIZE_URL, {
        response_type: "code",
        client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
        redirect_uri: config.SPOTIFY_CALLBACK_URL,
        scope: config.SPOTIFY_AUTH_SCOPES,
    });
    window.location.href = loginUrl;
}

export default signInWithSpotify