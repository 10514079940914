import React, { useContext, useMemo, useRef, useState } from 'react'
import { getMonth, shuffle } from '../../utils/'
import firebase from '../../services/firebase/index'
import { useToasts } from 'react-toast-notifications';
import { toBlob, toPng } from 'html-to-image';
import axios from 'axios'
import PlaylistModal from '../PlaylistModal'
import { useLocation } from 'react-router-dom'
import Modal from '../../components/Modal'
import { getRecently, createPlaylist, getArtists, getRecommendations, getTopList, getTracks, getArtistByIds } from '../../services'
import { Context } from '../../context'
import AnnounceKit from "announcekit-react";
import BellIcon from '../../style/img/icons/bell.svg'
import ReloadIcon from '../../icons/reload'
import MenuIcon from '../../icons/menu'
import config from '../../config'
import CustomSelect from '../../utils/CustomSelect'

export default function Index() {

    const { addToast } = useToasts()

    const location = useLocation();

    const [showModal, setShowModal] = useState(false)

    const [shareParams, setShareparams] = useState({ title: "", desc: "", link: "" })

    const [playlistText, setPlaylistText] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const { state: { user, tracks, recommendations, recently, artists, ranges, timeRange }, dispatch } = useContext(Context);

    const announceRef = useRef(null)

    const randomAvatar = useMemo(() => Math.floor((Math.random() * 24) + 1), [user])

    const rangeRef = useRef(null)

    const handleLogout = () => {
        const logoutTab = window.open('https://www.spotify.com/logout', '_blank');
        setTimeout(() => {
            window.location.hash = ""
            localStorage.clear();
            window.location.reload()
            logoutTab.close()
        }, 1000);
    }

    const getTitle = () => {
        switch (location.pathname) {
            case "/dashboard":
                return 'Your music streaming stats'
            case "/artists":
                return 'Top Artists'
            case "/tracks":
                return 'Top Tracks'
            case "/recent":
                return 'Recently Played'
            case "/discover":
                return 'New Discovery'
            case "/privacy-policy":
                return 'Privacy Policy'
            default:
                break;
        }
    }

    const handleSelectChange = () => {
        switch (location.pathname) {
            case "/dashboard":
                setTopList()
            case "/artists":
                setArtists()
            case "/tracks":
                setTracks()
            case "/recent":
                setRecently()
            case "/discover":
                return 'Discover'
            case "/privacy-policy":
                return 'Privacy Policy'
            default:
                break;
        }
    }
    const setArtists = async () => {
        let data = await getArtists(50, rangeRef.current.value);
        dispatch({ type: "getArtists", payload: data })
    }

    const setTracks = async () => {
        let data = await getTracks(50, rangeRef.current.value);
        dispatch({ type: "getTracks", payload: data })
    }

    const setTopList = async () => {
        let data = await getTopList(rangeRef.current.value, true);
        dispatch({ type: "getTopList", payload: data })
    }

    const setRecently = async () => {
        let data = await getRecently(50, rangeRef.current.value);
        dispatch({ type: "getRecently", payload: data })
    }

    const setNewRecommendations = async () => {
        setIsLoading(true)
        let data = await getRecommendations(50, false);
        dispatch({ type: "getRecommendations", payload: data })
        setIsLoading(false)
    }

    const createPlaylistTopTracks = async () => {
        let playlist = await createPlaylist(tracks.data.map(i => i.uri), `Echoes ✤ Top Tracks ✤ ${ranges[timeRange]} ✤ ${getMonth}`)
        dispatch({ type: "setPlaylist", payload: await addGenreToArtist(playlist) })
        setPlaylistText('top-tracks')
        setShowModal(true)
        generateAndPubishIMG(playlist, 'top-tracks')
    }
    const createPlaylistTopArtists = async () => {
        let shuffled = shuffle(artists.data.map(i => i.tracks).flat())
        let playlist = await createPlaylist(shuffled.slice(0, 100).map(i => i.uri), `Echoes ✤ Top Artists ✤ ${ranges[timeRange]} ✤ ${getMonth}`)
        dispatch({ type: "setPlaylist", payload: await addGenreToArtist(playlist) })
        setPlaylistText('top-artists')
        setShowModal(true)
        generateAndPubishIMG(playlist, 'top-artists')
    }
    const createPlaylistRecentTracks = async () => {
        let playlist = await createPlaylist(recently.data.map(i => i.track.uri), `Echoes ✤ Recently Played Tracks ✤ ${getMonth}`)
        dispatch({ type: "setPlaylist", payload: await addGenreToArtist(playlist) })
        setPlaylistText('recently-played')
        setShowModal(true)
        generateAndPubishIMG(playlist, 'recenty-played')
    }
    const createPlaylistDiscoverTracks = async () => {
        let playlist = await createPlaylist(recommendations.data.map(i => i.uri), `Echoes ✤ New Discovery ✤ ${getMonth}`)
        dispatch({ type: "setPlaylist", payload: await addGenreToArtist(playlist) })
        setPlaylistText('new-discovery')
        setShowModal(true)
        generateAndPubishIMG(playlist, 'new-discovery')
    }

    const generateAndPubishIMG = (playlist, type) => {
        setTimeout(() => {
            toPng(document.getElementById('my-node'), {
                width: 890,
                height: 445,
                style: {
                    opacity: "1",
                }
            })
                .then(async function (blob) {
                    let coverImg = document.getElementById('playlist-cover');
                    coverImg.style.backgroundImage = `url(${blob})`
                    const target = new URL("https://echoesapp.io/playlist/");
                    const tracksString = playlist.data.tracks.items.slice(0, 10).map(i => i.track?.name).join`, `;
                    let artistDesc = `Created a music playlist @echoessapp with My Top Artists @Spotify; ${playlist.data.tracks.items.slice(0, 10).map(i => i.track?.artists[0]?.name).join`, `}`;
                    let recentlyDesc = `Created a music playlist @echoessapp with My Recently Played Tracks @Spotify; ${tracksString} `
                    let discoveryDesc = `Created a music playlist @echoessapp with New Discovery @Spotify; ${tracksString} `
                    let tracksDesc = `Created a music playlist @echoessapp with My Top Tracks @Spotify; ${tracksString} `

                    const metas = {
                        'top-artists': {
                            title: "My Top Artists",
                            desc: artistDesc.length > 250 ? artistDesc.slice(0, 250) + '...' : artistDesc

                        },
                        'recenty-played': {
                            title: "My Recently Played Tracks",
                            desc: recentlyDesc.length > 250 ? recentlyDesc.slice(0, 250) + '...' : recentlyDesc
                        },
                        'new-discovery': {
                            title: "New Discovery",
                            desc: discoveryDesc.length > 250 ? discoveryDesc.slice(0, 250) + '...' : discoveryDesc
                        },
                        'top-tracks': {
                            title: "My Top Tracks",
                            desc: tracksDesc.length > 250 ? tracksDesc.slice(0, 250) + '...' : tracksDesc
                        }
                    }
                    target.searchParams.append("playlistId", playlist.data.id);
                    target.searchParams.append("title", metas[type].title);
                    target.searchParams.append("desc", metas[type].desc);
                    try {
                        let { data } = await axios.post(`${config.FIREBASE_BASE_URL}/generateUrl`,
                            { target: target.href })
                        setShareparams({
                            title: metas[type].title,
                            desc: metas[type].desc,
                            link: data.link.replace('http', 'https'),
                        })
                        toBlob(document.getElementById('my-node'), {
                            width: 790,
                            height: 384,
                            style: {
                                opacity: "1",
                            }
                        }).then(function (blob) {
                            const ref = firebase.storage().ref().child(`covers/${playlist.data.id}.png`);
                            ref.put(blob)
                        });
                    } catch (e) {
                        addToast('Something happend', { appearance: "error" })
                    }
                });
        }, 200);

    }
    const addGenreToArtist = async (playlist) => {
        let refObj = playlist.data.tracks.items;
        let artists = await getArtistByIds(refObj.map(i => i.track.artists[0].id).slice(0, 50))
        refObj.forEach(i => {
            let genre = artists.data.find(k => i.track.artists[0].id === k.id);
            i.track.artists[0].genre = genre?.genres[0]
            return i
        })
        return playlist
    }


    const handleCreatePlaylist = async () => {
        if (location.pathname === '/artists') {
            createPlaylistTopArtists()
            return
        }
        if (location.pathname === '/recent') {
            createPlaylistRecentTracks()
            return
        }
        if (location.pathname === '/tracks') {
            createPlaylistTopTracks()
            return
        }
        if (location.pathname === '/discover') {
            createPlaylistDiscoverTracks()
            return
        }

    }

    if (user.loading || user.error) return false

    return (
        <>
            {showModal ? <Modal setShowModal={setShowModal}>
                <PlaylistModal
                    setShowModal={setShowModal}
                    shareParams={shareParams}
                    playlistText={playlistText} />
            </Modal> : null}
            <div id="navbar-wrapper" className="main-content">
                <div className="header px-2">
                    <div class="dashboard__title d-flex align-items-center">
                        <span className="navbar-brand" id="sidebar-toggle">
                            <i onClick={() => {
                                const mains = document.querySelectorAll('.main-content')
                                const sidebar = document.getElementById('sidebar')
                                const toggle = document.getElementById('toggle-btn')
                                mains.forEach(i => {
                                    i.classList.toggle('menu-collapse')
                                })
                                sidebar.classList.toggle('menu-collapse')
                                toggle.classList.toggle('menu-collapse')
                            }} role="button" className="icon-button">
                                <MenuIcon id="toggle-btn" className="toggle-btn" />
                            </i>
                        </span>
                        <h1 class="page-title">{getTitle()}</h1>
                        {location.pathname === '/' || location.pathname === '/dashboard' || location.pathname === '/privacy-policy' ?
                            null : <button onClick={() => handleCreatePlaylist()} className="btn btn-dark ml-md-3">
                                <span className="ml-auto mr-auto font-weight-bold">Create a Spotify playlist</span>
                            </button>}
                        {location.pathname === '/discover' ?
                            <button onClick={() => setNewRecommendations()} className="btn btn-light ml-md-3">
                                <ReloadIcon className={`${isLoading ? 'rotate' : 'rotate-stop'}`} />
                                <span className="font-weight-bold ml-1">Load new tracks</span>
                            </button> : null
                        }
                    </div>
                    <div className="header-item-group">
                        {location.pathname === '/discover' || location.pathname === '/privacy-policy' || location.pathname === '/recent' ? null : <CustomSelect defaultValue={timeRange} rangeref={rangeRef} onChange={(e) => {
                            dispatch({ type: "setTimeRange", payload: e.target.value })
                            handleSelectChange()
                        }} />}
                        <div className="header-item">
                            <AnnounceKit embedWidget ref={announceRef} widget="https://announcekit.co/widgets/v2/5JPhK">
                                <span
                                    onClick={async () => {
                                        await announceRef.current.open()
                                    }} className="notification-click">
                                    <i className="icon-bell">
                                        <img alt="Announcekit Icon" src={BellIcon}></img>
                                    </i>
                                </span>
                            </AnnounceKit>
                            {/* Notification tooltip */}
                        </div>
                        <div className="header-item">
                            <span className="user-avatar__link">
                                <i className="user-avatar">
                                    {user.data.images.length > 0 ?
                                        <img alt="User Avatar" src={user.data.images[0].url} /> :
                                        <img alt="User Avatar" src={`/img/avatars/avatar-${randomAvatar}.png`} />
                                    }
                                </i>
                            </span>
                            {/* User info Tooltip */}
                            <div className="tooltip-block" id="tooltip-block">
                                <span className="romb" />
                                <div className="row relative align-items-center tooltip-block__header">
                                    <div className="user-img1">
                                        {user.data.images.length > 0 ?
                                            <img alt="User Avatar" src={user.data.images[0].url} /> :
                                            <img alt="User Avatar" src={`/img/avatars/avatar-${randomAvatar}.png`} />
                                        }
                                    </div>
                                    <span>{user.data.display_name}</span>
                                </div>
                                <div className="settings-block">
                                    <div className="d-flex align-items-center settings-block_item settings-block__subscription">
                                        <i className="preferences-icon log-out-icon">
                                            <svg className="userIcon">
                                                <use xlinkHref="#logOut" />
                                            </svg>
                                        </i>
                                        <span className="text-white" role="button" onClick={handleLogout}>Log out</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
