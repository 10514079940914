import React, { useEffect, useContext } from 'react'
import { Context } from "../../context";
import Item from '../../components/Recently/item'
import { getRecently } from '../../services'

export default function Artists() {

    const { state: { timeRange, recently }, dispatch } = useContext(Context);

    const setRecently = async () => {
        let data = await getRecently(50, timeRange);
        dispatch({ type: "getRecently", payload: data })
    }

    useEffect(() => {
        setRecently();
    }, [])

    return (
        <section className="bg-light-gray" id="content-wrapper">
            <div className="container-fluid">
                <div className=" revenue__title revenue__title_two d-flex justify-content-between align-items-center">
                    <ul className="top-artist-list">
                        {recently.data.map((i, k) =>
                            <Item refreshDataset={setRecently} items={recently.data} key={i.id} item={{ ...i.track, index: k + 1, loading: recently.loading }} ></Item>
                        )}
                    </ul>
                </div>
            </div >
        </section >
    )
}
