import React from "react";
import PropTypes from 'prop-types'

function Icon(props) {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            fill="none"
            {...props}
            viewBox="0 0 20 19"
        >
            <path
                stroke={props.strokecolor}
                strokeLinecap="round"
                d="M9.633 3.538l.367.395.367-.395.75-.808C12.9.809 15.839.75 17.69 2.598c1.862 1.858 1.92 4.937.127 6.868l-7.45 8.029a.5.5 0 01-.734 0l-7.45-8.029C.39 7.535.448 4.456 2.31 2.598 4.16.751 7.1.808 8.883 2.73l.75.808z"
            ></path>
        </svg>
    );
}

Icon.defaultProps = {
    strokecolor: "#000"
};

Icon.prototype = {
    strokecolor: PropTypes.string
}

export default Icon;
