import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';

export default function Index({ children }) {
    const [modalContainer] = useState(document.createElement('div'));
    useEffect(() => {
        // Find the root element in your DOM
        let modalRoot = document.getElementById('modal-root');
        // If there is no root then create one
        if (!modalRoot) {
            const tempEl = document.createElement('div');
            tempEl.id = 'modal-root';
            document.body.append(tempEl);
            modalRoot = tempEl;
        }
        // Append modal container to root
        modalRoot.appendChild(modalContainer);
        modalContainer.className = 'modal'
        modalContainer.style.width = "100%";
        return function cleanup() {
            // On cleanup remove the modal container
            modalRoot.removeChild(modalContainer);
        };
    }, [modalContainer]); // <- The empty array tells react to apply the effect on mount/unmount
    return ReactDOM.createPortal(children, modalContainer);
}

