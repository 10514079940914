import React from "react";

function Icon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="31"
            fill="none"
            viewBox="0 0 31 31"
        >
            <path
                fill="#000"
                d="M15.86 1.556c-7.872 0-14.274 6.28-14.274 14 0 7.723 6.402 14.002 14.274 14.002 7.873 0 14.275-6.28 14.275-14.001 0-7.722-6.402-14.001-14.275-14.001zm0 1.866c6.844 0 12.372 5.422 12.372 12.135S22.704 27.69 15.86 27.69c-6.844 0-12.372-5.421-12.372-12.134S9.017 3.422 15.861 3.422zm-5.808 7.468a.969.969 0 00-.659.307.931.931 0 00-.244.675.92.92 0 00.314.646.956.956 0 00.688.239h11.42a.968.968 0 00.68-.269.934.934 0 00.284-.665.918.918 0 00-.283-.665.954.954 0 00-.682-.268H10.15a.963.963 0 00-.098 0zm0 3.733a.967.967 0 00-.659.308.931.931 0 00-.244.675.921.921 0 00.314.645.958.958 0 00.688.24h11.42a.968.968 0 00.68-.27.933.933 0 00.284-.664.918.918 0 00-.283-.665.954.954 0 00-.682-.268H10.15a.963.963 0 00-.098 0zm0 3.734a.969.969 0 00-.659.308.93.93 0 00-.244.674.92.92 0 00.314.646.956.956 0 00.688.239h11.42a.968.968 0 00.68-.269.934.934 0 00.284-.664.917.917 0 00-.283-.665.954.954 0 00-.682-.269H10.15a.963.963 0 00-.098 0z"
            ></path>
        </svg>
    );
}

export default Icon;
