import React, { useContext } from 'react'
import { Context } from '../../context'
import Loader from '../../components/Loader/itemLoader'
import Play from '../../icons/play'
import { saveTrack, removeSavedTrack } from '../../services/index'
import HeartIcon from '../../icons/heart'
import { timeConvert } from '../../utils'

export default function Item({ item, refreshDataset, items }) {

    const { dispatch } = useContext(Context);

    const handleSaveClick = async () => {

        if (item.isSaved) {
            await removeSavedTrack(item.id);
            refreshDataset()
        } else {
            await saveTrack(item.id)
            refreshDataset()
        }
    }

    const getOrderedTracks = (index) => {
        let tracks = [...items]
        let splicedTracks = tracks.splice(index, (tracks.length))
        return [...splicedTracks, ...tracks].map(i => { return { uri: i.uri ? i.uri : i.track.uri, id: i.id ? i.id : i.track.id } });
    }

    if (item.loading) return <Loader />

    return (
        <li >
            <div className="d-flex figure-wrapper">
                <p className="font-weight-bold">{item.index}</p>
                <Play role="button" className="ml-2" onClick={() => {
                    dispatch({
                        type: "setPlayingTracks", payload: {
                            data: getOrderedTracks(item.index - 1)
                        }
                    })
                }} name={item.name} color="#000000" />
            </div>
            <figure className="figure">
                <img alt={item.album.name} width="50" height="50" src={item.album.images.length ? item.album.images[1].url : null}></img>
                <figcaption>
                    <span className="font-weight-bold">{item.name}</span>
                    <span className="figure-subtitle">{item.album.artists[0].name}</span>
                </figcaption>
            </figure>
            <div className="like-wrapper" >
                <HeartIcon onClick={handleSaveClick} fill={item.isSaved ? 'black' : 'none'} />
                <p className="font-weight-bold">{timeConvert((item.duration_ms))}</p>
            </div>
        </li >
    )
}
