import axios from './axios'

const removeSavedTrack = async (trackId) => {
    try {
        return await axios.delete('/me/tracks', {
            data: {
                ids: [trackId]
            }
        })

    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default removeSavedTrack