import axios from './axios'

const createPlaylist = async (tracks, name) => {

    try {
        let { data: { id } } = await axios.post('/me/playlists', {
            name,
            public: true
        })

        await axios.post(`/playlists/${id}/tracks`, {
            uris: tracks
        })

        let { data } = await axios.get(`/playlists/${id}`)

        return {
            data,
            error: false,
            loading: false
        }

    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default createPlaylist