import React from 'react'
import PropTypes from 'prop-types'
import Item from './item'
import { Link } from 'react-router-dom'


function Recently({ items, refreshDataset }) {

    if (items.length === 0) {
        return (
            <section id="content-wrapper">
                <div className="container-fluid">
                    <div className="dashboard__title d-flex justify-content-between">
                        <h1 className="page-title">Recently played tracks</h1>
                    </div>
                    <div className="mt-4">
                        <h6 className="mb-3">Not enough data to display. Listen more music and visit the app again.</h6>
                        <Link to="/recent" className="btn btn-dark btn__refresh_41 ml-0 mr-3 mx-xl-0 my-md-0 my-3">
                            <span className="m-auto">See all Recently Played Tracks</span>
                        </Link>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section id="content-wrapper">
            <div className="container-fluid">
                <div className="dashboard__title d-flex justify-content-between">
                    <h1 className="page-title">Recently played tracks</h1>
                </div>
                <div className="mt-4">
                    <ul className="recenty-played-wrapper">
                        {items.map((i, k) => (
                            <Item key={k} refreshDataset={refreshDataset} items={items} item={{ ...i.track, index: k + 1 }} />
                        ))}
                    </ul>
                    <Link to="/recent" className="btn btn-dark btn__refresh_41 ml-0 mr-3 mx-xl-0 my-md-0 my-3">
                        <span className="m-auto">See all Recently Played Tracks</span>
                    </Link>
                </div>
            </div>
        </section>
    )
}

Recently.propTypes = {
    items: PropTypes.array
}

export default Recently