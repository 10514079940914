import axios from './axios'

const followArtist = async (artistId) => {
    try {
        return await axios.put('/me/following', {}, {
            params: {
                ids: artistId,
                type: "artist"
            }
        })
    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default followArtist