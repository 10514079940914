import React, { useContext, useMemo } from "react";
import { Context } from '../context'
import PropTypes, { oneOfType } from 'prop-types'

function Icon(props) {

    const { state } = useContext(Context);


    const isPlaying = useMemo(() => {
        if (!state.currentlyPlaying.loading) {
            if (Array.isArray(props.name)) {
                if (props.name[0] === state.currentlyPlaying.data.track_window.current_track.artists[0].uri) return true
                return false
            } else {
                if (props.name === state.currentlyPlaying.data.track_window.current_track.name) return true
                return false
            }
        } else {
            return false
        }

    }, [state.currentlyPlaying])

    if (state.currentlyPlaying.loading) return (
        <svg
            role="button"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="none"
            viewBox="0 0 36 36"
        >
            <g opacity="0.7">
                <circle cx="18" cy="18" r="17.5" stroke={props.color}></circle>
                <path
                    fill={props.color}
                    d="M24.848 18.5l-10.696-6.175v12.35L24.848 18.5z"
                ></path>
            </g>
        </svg>
    )

    return (
        <svg
            role="button"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="none"
            viewBox="0 0 36 36"
        >
            { !state.currentlyPlaying.data.paused && isPlaying ?
                <g opacity={0.7} stroke={props.color}>
                    <circle cx={18} cy={18} r={17.5} />
                    <path d="M23 11v14m-10 0V11" strokeWidth={3} />
                </g>
                :
                <g opacity="0.7">
                    <circle cx="18" cy="18" r="17.5" stroke={props.color}></circle>
                    <path
                        fill={props.color}
                        d="M24.848 18.5l-10.696-6.175v12.35L24.848 18.5z"
                    ></path>
                </g>
            }
        </svg>
    );
}

Icon.propTypes = {
    color: PropTypes.string,
    name: oneOfType([PropTypes.string, PropTypes.array]),
}

Icon.defaultProps = {
    color: '#fff'
};


export default Icon;
