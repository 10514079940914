import React from "react";

function Icon() {
    return (
        <svg
            opacity="0.7"
            strokeWidth="1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
        >
            <path d="M11 5L6 9 2 9 2 15 6 15 11 19 11 5z"></path>
            <path d="M19.07 4.93a10 10 0 010 14.14M15.54 8.46a5 5 0 010 7.07"></path>
        </svg>
    );
}

export default Icon;
