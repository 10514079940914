import React, { useContext } from 'react'
import Loader from '../Loader'
import { Context } from '../../context'
import PropTypes from 'prop-types'
import Play from '../../icons/play'
import { Link } from 'react-router-dom'

function Top({ items, data, loading }) {

    const { dispatch } = useContext(Context);

    const getOrderedTracks = (index) => {
        let tracks = [...items]
        let splicedTracks = tracks.splice(index + 1, (tracks.length - index + 1))
        return [...splicedTracks, ...tracks].map(i => { return { uri: i.uri, id: i.id } });
    }

    if (loading) return <Loader />

    if (items.length === 0) {
        return (
            <div className="col-xl-4">
                <div className="card">
                    <Link to={`/${data.type.toLowerCase()}`} className="btn btn-dark mb-2 font-weight-bold">
                        Top {data.type}
                    </Link>
                    <h6>Not enough data to display. Listen more music and visit the app again.</h6>
                </div>
            </div>
        )
    }

    return (
        <>
            {data.isTrack ? <div className="col-xl-4">
                <div className="card">
                    <div style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${items[0].album.images.length ? items[0].album.images[1].url : null}')` }} className="card-background">
                        <div className="card-body  d-flex flex-column justify-content-end">
                            <Link to={`/${data.type.toLowerCase()}`} className="btn btn__white font-weight-bold">
                                Top {data.type}
                            </Link>
                            <div className="d-flex justify-content-between"><h3 className="text-white">{items[0].name}</h3>
                                <Play
                                    name={items[0].name}
                                    onClick={() => dispatch({ type: "setPlayingTracks", payload: { data: items.map(i => { return { uri: i.uri, id: i.id } }) } })}
                                /></div>
                            <span className="text-white">{items[0].artists[0].name}</span>
                        </div>
                    </div>
                    <div className="px-0 py-3">
                        <div className="revenue__header d-flex justify-content-between">
                            <div className="revenue__title revenue__title_two d-flex justify-content-between align-items-center">
                                <ul className="top-list">
                                    {items.slice(1).map((i, k) => (
                                        <li key={k}>
                                            <h6 className="font-weight-bold">{k + 1}</h6>
                                            <figure>
                                                <img alt={i.album.name} width="50" height="50" src={i.album.images.length ? i.album.images[2].url : null}></img>
                                                <figcaption title={i.name}>{i.name} <br />
                                                    <span className="figure-subtitle">{i.artists[0].name}</span>
                                                </figcaption>
                                            </figure>
                                            <Play
                                                onClick={() => {
                                                    dispatch({ type: "setPlayingTracks", payload: { data: getOrderedTracks(k) } })
                                                }}
                                                name={i.name} color="#000000" />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Link to={`/${data.type.toLowerCase()}`} className="btn btn-dark btn__refresh_41 ml-0 mr-3 mx-xl-0 my-md-0 my-3">
                        <span className="m-auto">See all Top {data.type}</span>
                    </Link>
                </div>
            </div> : <div className="col-xl-4">
                <div className="card">
                    <div style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url('${items[0].images.length ? items[0].images[1].url : null}')` }} className="card-background">
                        <div className="card-body  d-flex flex-column justify-content-end">
                            <Link to={`/${data.type.toLowerCase()}`} className="btn btn__white font-weight-bold">
                                Top {data.type}
                            </Link>
                            <div className="d-flex mb-2 justify-content-between"><h3 className="text-white">{items[0].name}</h3>
                                <Play
                                    onClick={() => dispatch({ type: "setPlayingTracks", payload: { data: items[0].tracks.map(i => { return { uri: i.uri, id: i.id } }) } })}
                                    name={[items[0].uri]} />
                            </div>
                        </div>
                    </div>
                    <div className="px-0 py-3">
                        <div className="revenue__header d-flex justify-content-between">
                            <div className="revenue__title revenue__title_two d-flex justify-content-between align-items-center">
                                <ul className="top-list">
                                    {items.slice(1).map((i, k) => (
                                        <li key={k}>
                                            <h6 className="font-weight-bold">{k + 1}</h6>
                                            <figure>
                                                <img alt={i.name} width="50" height="50" src={i.images.length ? i.images[2].url : null}></img>
                                                <figcaption title={i.name}>{i.name}</figcaption>
                                            </figure>
                                            <Play
                                                onClick={() => {
                                                    dispatch({ type: "setPlayingTracks", payload: { data: i.tracks.map(i => { return { uri: i.uri, id: i.id } }) } })
                                                }}
                                                name={[i.uri]} color="#000000" />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Link to={`/${data.type.toLowerCase()}`} className="btn btn-dark btn__refresh_41 ml-0 mr-3 mx-xl-0 my-md-0 my-3">
                        <span className="m-auto">See all Top {data.type}</span>
                    </Link>
                </div>
            </div>}
        </>


    )
}

Top.defaultProps = {
    isTrack: true
};


Top.propTypes = {
    isTrack: PropTypes.bool,
    items: PropTypes.array
}

export default Top