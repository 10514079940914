import React, { useContext } from 'react'
import { useToasts } from 'react-toast-notifications';
import { Context } from "../../context";
import TwitterIcon from '../../icons/share-twitter'
import FacebookIcon from '../../icons/share-facebook'
import TumblrIcon from '../../icons/share-tumblr'

export default function PlaylistModal({ shareParams, playlistText, setShowModal }) {

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const { addToast } = useToasts()

    const { state: { playlist }, dispatch } = useContext(Context);

    return (
        <div className="modal-content card">
            <div className="card-body">
                <div className="d-flex mb-4">
                    <div className="flex-grow-1">
                        <h1 className="title">Playlist Created</h1>
                        <p className="sub-title">Your playlist created, added to your spotify library and ready to listen here.</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <button
                            onClick={() => {
                                dispatch({
                                    type: "setPlayingTracks",
                                    payload: { data: playlist.data.tracks.items.map(i => { return { uri: i.track.uri, id: i.track.id } }) }
                                })
                                setShowModal(false)
                            }}
                            className="btn btn-dark mr-2 listen-btn">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="18"
                                fill="none"
                                viewBox="0 0 19 18"
                            >
                                <circle cx="9.5" cy="9" r="7.5" stroke="#fff"></circle>
                                <path fill="#fff" d="M12.543 9.222L7.79 6.478v5.488l4.753-2.744z"></path>
                            </svg>
                            <span className="listen-here-text">Listen now</span></button>
                        <svg
                            onClick={() => setShowModal(false)}
                            className="close-btn"
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="none"
                            viewBox="0 0 48 48"
                        >
                            <path
                                fill="#fff"
                                d="M0 6a6 6 0 016-6h36a6 6 0 016 6v36a6 6 0 01-6 6H6a6 6 0 01-6-6V6z"
                            ></path>
                            <path
                                stroke="#B2B2B2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 30l12-12m-12 0l12 12"
                            ></path>
                            <path
                                fill="#B2B2B2"
                                d="M6 1h36v-2H6v2zm41 5v36h2V6h-2zm-5 41H6v2h36v-2zM1 42V6h-2v36h2zm5 5a5 5 0 01-5-5h-2a7 7 0 007 7v-2zm41-5a5 5 0 01-5 5v2a7 7 0 007-7h-2zM42 1a5 5 0 015 5h2a7 7 0 00-7-7v2zM6-1a7 7 0 00-7 7h2a5 5 0 015-5v-2z"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div id="my-node" className="dark-bg text-white playlist-modal">
                    <div className="d-flex flex-column justify-content-center">
                        <img width="300" height="216" className="playlist-text-img" src={`/${playlistText}.png`}></img>
                        <figure className="mt-4">
                            <img className="top-logo" src="/echoes.png"></img>
                        </figure>
                    </div>
                    <div className="top-playlist-wrapper">
                        {playlist.data.tracks.items.slice(0, 10).map((i, k) => (
                            <figure key={i.id} className="figure figure-playlist">
                                <img width={50} height={50} alt={i.track.album.images[0].url} src={i.track.album.images[0].url} />
                                <figcaption>
                                    <span className="figure-subtitle mb-1">#0{k + 1}</span>
                                    <span className="font-weight-bold track">{playlistText === 'top-artists' ? i.track.artists[0].name : i.track.name}</span>
                                    <span className="figure-subtitle"> {playlistText === 'top-artists' ? i.track.artists[0].genre : i.track.artists[0].name}</span>
                                </figcaption>
                            </figure>
                        ))}
                    </div>
                </div>
                <div id="playlist-cover"></div>
                <div className="d-flex justify-content-between flex-wrap mt-4">
                    <div className="d-flex flex-column flex-grow-1">
                        <h3>Spotify playlist link</h3>
                        <div className="copy-wrapper mt-3">
                            <input placeholder="https://playlist.echoesapp.io" type="text" readOnly value={shareParams.link}></input>
                            <span
                                onClick={() => {
                                    copyToClipboard(shareParams.link)
                                    addToast('Copied!', { appearance: "success", autoDismiss: true, autoDismissTimeout: 2000 })
                                }}
                                role="button" className="ml-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="none"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        fill="#828D9C"
                                        d="M24.14 8.929h-3.427V5.5c.287.22 3.207 3.14 3.427 3.428zM16.133 6.57a4.009 4.009 0 013.008 1.382V5h-6.285a2.353 2.353 0 00-2.213 1.571h5.49zM21.69 10.5a4.008 4.008 0 011.38 3.008v10.204a2.354 2.354 0 001.572-2.212v-11H21.69zm-4.12-1.856v3.427h3.428c-.06-.078-3.349-3.366-3.427-3.427zm0 4.999A1.574 1.574 0 0116 12.07V8.143H9.712A2.36 2.36 0 007.355 10.5v14.143A2.36 2.36 0 009.713 27h9.428a2.36 2.36 0 002.357-2.357v-11H17.57zm0 8.643h-6.285a.786.786 0 010-1.572h6.286a.786.786 0 010 1.572zm0-3.143h-6.285a.785.785 0 010-1.572h6.286a.785.785 0 010 1.572zm0-3.143h-6.285a.786.786 0 010-1.571h6.286a.786.786 0 010 1.571z"
                                    ></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column social-share-wrapper align-self-end">
                        <p className="mb-2">Share in your social networks </p>
                        <div className="d-flex justify-content-center">
                            <a rel="noreferrer" target="_blank" href={`http://twitter.com/share?url=${shareParams.link}&text=${shareParams.desc}`}>
                                <TwitterIcon />
                            </a>
                            <a rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${shareParams.link}`} target="_blank">
                                <FacebookIcon />
                            </a>
                            <a rel="noreferrer" target="_blank" href={`http://tumblr.com/widgets/share/tool?canonicalUrl=${shareParams.link}`}>
                                <TumblrIcon />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
