import React, { useEffect, useContext } from 'react'
import { useToasts } from 'react-toast-notifications';
import { Context } from '../context'
import { getCurrentUser, signInWithSpotify } from '../services'
import Aside from '../components/Aside'
import Footer from '../components/Footer'
import Email from '../components/Email'
import '../style/motherboard/style.css'
import '../style/motherboard/custom.scss'

function Layout({ children }) {

    const { addToast } = useToasts();

    const { dispatch, state: { error } } = useContext(Context)

    const setCurrentUser = async () => {
        let data = await getCurrentUser();
        dispatch({ type: "getCurrentUser", payload: data })
    }

    useEffect(() => {
        setCurrentUser()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (error.message) {
            if (error.status == "401") {
                signInWithSpotify()
                return false
            }
            addToast(error.message, { appearance: "error" })
        }
    }, [error])

    return (
        <>
            <Aside />
            <main id="main" className="main main-content">
                {children}
                <Email />
            </main>
            <Footer />
        </>
    )
}

export default Layout
