import axios from './axios'
import isFollowingArtists from './isFollowingArtists'

const getArtists = async (limit = 50, time_range = "long_term") => {
    try {
        let { data: { items } } = await axios.get('/me/top/artists', {
            params: {
                limit,
                time_range,
                offset: "0"
            }
        })
        let isFollowArtists = await isFollowingArtists(items.map(i => i.id))
        items = await Promise.all(items.map(async (i, k) => {
            let { data: { tracks } } = await axios.get(`/artists/${i.id}/top-tracks`, {
                params: {
                    country: "US"
                }
            })
            return {
                ...i,
                isFollow: isFollowArtists[k],
                tracks
            }
        }));
        return {
            data: items,
            error: false,
            loading: false
        }

    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export const getArtistByIds = async (artistsId) => {
    try {
        let { data: { artists } } = await axios.get('/artists', {
            params: {
                ids: artistsId.join(','),
            }
        })
        return {
            data: artists,
            error: false,
            loading: false
        }

    } catch (e) {
        let { response: { data: { error } } } = { ...e }
        return {
            error: true,
            status: error.status,
            message: error.message
        }
    }
}

export default getArtists